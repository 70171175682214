import { ReactElement } from 'react';
import { selectUser } from 'ducks/user/selectors';
import { TITAN_ORGANIZATION } from 'products/titan/operators/campaign-roles-list/enums';
import { useAppSelector } from 'store/hooks';

interface PlatformSecurityProps {
  include?: boolean;
  children: ReactElement | null;
  organizations: TITAN_ORGANIZATION[];
}
export const PlatformSecurity: React.FC<PlatformSecurityProps> = ({ children, include = false, organizations = [] }) => {
  const { organization_id } = useAppSelector(selectUser);

  const organization = organization_id as TITAN_ORGANIZATION;
  if (!organizations.includes(organization) && !include) {
    return children;
  }

  if (organizations.includes(organization) && include) {
    return children;
  }

  return null;
};
