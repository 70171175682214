import React from 'react';
import { Outlet } from 'react-router';
import { selectUser } from 'ducks/user/selectors';
import ErrorPage from 'products/public/pages/error/ErrorPage';
import { TITAN_ORGANIZATION } from 'products/titan/operators/campaign-roles-list/enums';
import { TITAN } from 'routes';
import { useAppSelector } from 'store/hooks';

import { Breadcrumb } from 'components/layout/navigation/Breadcrumb';
import { Navigation } from 'components/layout/navigation/Navigation';

interface PrivateRouteProps {
  include?: boolean;
  organizations: TITAN_ORGANIZATION[];
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ organizations, include = false }) => {
  const { organization_id } = useAppSelector(selectUser);
  const organization = organization_id as TITAN_ORGANIZATION;

  if (!organizations.includes(organization) && !include) {
    return <Outlet />;
  }

  if (organizations.includes(organization) && include) {
    return <Outlet />;
  }
  return (
    <>
      <Navigation>
        <Navigation>
          <Breadcrumb label="404" />
        </Navigation>
      </Navigation>
      <ErrorPage path={`${TITAN.APPLICATION.path}/${TITAN.ADVERTISERS.path}`} page="Advertisers" />
    </>
  );
};
