import { LibraDspIdentifier } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/libra/operators/slice';

const initialState: LibraDspIdentifier[] = [];

export const libraDspIdentifiersSlice = createSlice({
  name: SLICE.LIBRA_DSP_IDENTIFIERS,
  initialState,
  reducers: {
    clearLibraDspIdentifiers: () => {
      return initialState;
    },
    setLibraDspIdentifiers: (_, action: PayloadAction<LibraDspIdentifier[]>) => action.payload,
  },
});

export const { clearLibraDspIdentifiers, setLibraDspIdentifiers } = libraDspIdentifiersSlice.actions;

export default libraDspIdentifiersSlice.reducer;
