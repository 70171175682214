import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, DropdownFilter, Form, InputText, Modal } from '@cognitiv/cassiopeia-ui';
import { getValidationError, mergeSearchQuery } from '@cognitiv/cicada-ui';
import { TitanAdvertiser, TitanPartner, useClientContext } from '@cognitiv/galaxy-api';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { updateSettings } from 'ducks/settings/slices';
import { useValidate } from 'hooks/useValidate';
import { validation } from 'products/titan/modals/manage-advertiser/validation';
import { titan_advertiser_default } from 'products/titan/operators/advertiser/defaults';
import { selectTitanAdvertiser } from 'products/titan/operators/advertiser/selectors';
import { selectTitanPartners } from 'products/titan/operators/partners/selectors';
import { TITAN } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { uuidv4 } from 'utils/uuid';

import cn from 'products/titan/modals/Modal.module.scss';

export const ManageTitanAdvertiser = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { Titan, Scorpio, Orion, handleError } = useClientContext();

  const { is_open } = useAppSelector((state) => selectModal(state, 'manage_titan_advertiser'));
  const titan_partners = useAppSelector(selectTitanPartners);
  const titan_advertiser = useAppSelector(selectTitanAdvertiser);
  const [partners, setPartners] = useState<TitanPartner[]>([]);

  const [form, setForm] = useState<TitanAdvertiser>({ ...titan_advertiser_default });

  const { errors, validate, resetErrors, resetError } = useValidate(form, validation);

  useEffect(() => {
    setPartners(titan_partners);
    setForm({ ...titan_advertiser });
  }, [titan_advertiser, titan_partners]);

  const onChange = useCallback(
    (item: Partial<TitanAdvertiser>) => {
      resetError(item);
      setForm((prev) => ({ ...prev, ...item }));
    },
    [resetError],
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const is_valid = validate();

      if (!is_valid) return;

      const queries = mergeSearchQuery(search, { update: uuidv4() });

      dispatch(updateSettings({ loading: true }));

      const { advertiser_id } = form;

      const payload = {
        advertiser_name: form.advertiser_name,
        category_id: 1,
        domain_url: form.domain_url,
      };

      if (form.advertiser_id) {
        await Titan.updateTitanAdvertiser(Number(advertiser_id), payload);
        navigate(`${pathname}${queries}`);
      }
      if (!form.advertiser_id) {
        const advertiser_id = await Titan.createTitanAdvertiser(Number(form.partner_id), payload);
        await Scorpio.getScorpioSynchronizeTitanAdvertisers();
        await Orion.getOrionSynchronizeTitanAdvertisers();
        navigate(`${TITAN.APPLICATION.path}/${TITAN.ADVERTISERS.path}/${advertiser_id}`);
      }

      onClose();
    } catch (err) {
      handleError(err);
    }
  };

  const onSelectPartner = useCallback(
    (option: TitanPartner) => onChange({ partner_name: option.partner_name, partner_id: option.partner_id }),
    [onChange],
  );

  const onChangePartner = useCallback(
    async ({ value }: { value: string }) => {
      try {
        const partners = await Titan.getTitanPartners({ filter: value });
        setPartners(partners);
      } catch (error) {
        handleError(error);
      }
    },
    [Titan, handleError],
  );

  const onChangeAdvertiser = useCallback((data: { value: string }) => onChange({ advertiser_name: data.value }), [onChange]);

  const onClose = useCallback(() => {
    resetErrors();
    setForm({ ...titan_advertiser_default });
    dispatch(updateModal({ manage_titan_advertiser: { is_open: false } }));
  }, [dispatch, resetErrors]);

  return (
    <Modal
      title={form.advertiser_id ? 'Update Advertiser' : 'Create Advertiser'}
      identifier="manage_titan_advertiser"
      is_open={is_open}
      width={440}
      onClose={onClose}
    >
      <Form onSubmit={onSubmit}>
        <div className={cn.body}>
          <InputText
            auto_focus
            label="Name"
            margin="0px 0px 16px"
            value={form.advertiser_name}
            onChange={onChangeAdvertiser}
            error={getValidationError<TitanAdvertiser>({ errors, key: 'advertiser_name' })}
          />
          <DropdownFilter
            label="Partner"
            option_key="partner_name"
            value={form.partner_name}
            disabled={!!form.advertiser_id}
            debounce={300}
            options={partners}
            onSelect={onSelectPartner}
            onChange={onChangePartner}
            error={getValidationError<TitanAdvertiser>({ errors, key: 'partner_id' })}
          />
        </div>
        <div className={cn.footer}>
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">{form.advertiser_id ? 'Save' : 'Create'}</Button>
        </div>
      </Form>
    </Modal>
  );
};
