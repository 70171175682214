import { LibraTaxonomy } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/libra/operators/slice';

const initialState: LibraTaxonomy[] = [];

export const libraTaxonomiesSlice = createSlice({
  name: SLICE.LIBRA_TAXONOMIES,
  initialState,
  reducers: {
    clearLibraTaxonomies: () => initialState,
    setLibraTaxonomies: (_, action: PayloadAction<LibraTaxonomy[]>) => action.payload,
  },
});

export const { clearLibraTaxonomies, setLibraTaxonomies } = libraTaxonomiesSlice.actions;

export default libraTaxonomiesSlice.reducer;
