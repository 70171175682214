import { OrionDeal } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/orion/operators/slice';

const initialState: OrionDeal[] = [];

export const orionDealsSlice = createSlice({
  name: SLICE.ORION_DEALS,
  initialState,
  reducers: {
    clearOrionDeals: () => initialState,
    setOrionDeals: (_, action: PayloadAction<OrionDeal[]>) => action.payload,
  },
});

export const { clearOrionDeals, setOrionDeals } = orionDealsSlice.actions;

export default orionDealsSlice.reducer;
