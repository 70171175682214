import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { selectSettings } from 'ducks/settings/selectors';
import Lottie from 'lottie-react';
import { useAppSelector } from 'store/hooks';

import { LOADING_VARIANTS } from 'components/layout/loading/enums';
import loader from 'components/layout/loading/loader.json';
import cn from 'components/layout/loading/Loading.module.scss';

const { PUBLIC_URL } = process.env;

export const Loading = memo(({ variant }: { variant: LOADING_VARIANTS }) => {
  const { loading } = useAppSelector(selectSettings);
  const [visible, setVisible] = useState(false);

  useLayoutEffect(() => {
    if (loading) {
      setVisible(true);
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setVisible(false);
      }, 400);
    }
  }, [loading]);

  if (!loading && !visible) return null;

  return (
    <>
      <div className={cn.blur} />
      <div
        className={classNames(
          cn.loading,
          { [cn.dark]: variant === LOADING_VARIANTS.DARK },
          { [cn.light]: variant === LOADING_VARIANTS.LIGHT },
          { [cn.transparent]: variant === LOADING_VARIANTS.TRANSPARENT },
        )}
      >
        <Lottie animationData={loader} />
      </div>
    </>
  );
});

export const SmallSpinner = () => <img className={classNames(cn.spinner, cn.small)} src={`${PUBLIC_URL}/spinner.gif`} alt="spinner-small" />;
