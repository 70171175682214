import { ScorpioEventSource } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/scorpio/operators/slice';

const initialState: ScorpioEventSource[] = [];

export const scorpioEventsSlice = createSlice({
  name: SLICE.SCORPIO_EVENTS,
  initialState,
  reducers: {
    clearScorpioEvents: () => initialState,
    setScorpioEvents: (_, action: PayloadAction<ScorpioEventSource[]>) => action.payload,
  },
});

export const { clearScorpioEvents, setScorpioEvents } = scorpioEventsSlice.actions;

export default scorpioEventsSlice.reducer;
