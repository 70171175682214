import libraDspIdentifierSlice from 'products/libra/operators/dsp-identifier/slices';
import libraDspIdentifiersSlice from 'products/libra/operators/dsp-identifiers/slices';
import libraFilterAttributesSlice from 'products/libra/operators/filter-attributes/slices';
import libraTaxonomiesSlice from 'products/libra/operators/taxonomies/slices';

export const libra = {
  libra_filter_attributes: libraFilterAttributesSlice,
  libra_taxonomies: libraTaxonomiesSlice,
  libra_dsp_identifier: libraDspIdentifierSlice,
  libra_dsp_identifiers: libraDspIdentifiersSlice,
};
