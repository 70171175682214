import { ScorpioEventSource } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { scorpio_event_default } from 'products/scorpio/operators/event/defaults';
import { SLICE } from 'products/scorpio/operators/slice';

export const initialState: ScorpioEventSource = { ...scorpio_event_default };

export const scorpioEventSlice = createSlice({
  name: SLICE.SCORPIO_EVENT,
  initialState,
  reducers: {
    clearScorpioEvent: () => {
      return initialState;
    },
    setScorpioEvent: (_, action: PayloadAction<ScorpioEventSource>) => {
      return action.payload;
    },
  },
});

export const { clearScorpioEvent, setScorpioEvent } = scorpioEventSlice.actions;

export default scorpioEventSlice.reducer;
