import { createSlice } from '@reduxjs/toolkit';
import { Settings } from 'ducks/settings/types';
import { SLICE } from 'ducks/slice';
import { THEME } from 'hooks/useTheme';

const initialState: Settings = {
  sw_registration: { waiting: false },
  orion: false,
  titan: false,
  libra: false,
  scorpio: false,
  loading: false,
  loading_statistics: false,
  is_menu_open: false,
  is_application_menu_open: false,
  theme: THEME.LIGHT,
};
export const settingsSlice = createSlice({
  name: SLICE.SETTINGS,
  initialState,
  reducers: {
    updateSettings: (state, action) => {
      return { ...state, ...action.payload };
    },
    triggerOrionLookups: (state) => {
      return { ...state, orion: true };
    },
    triggerTitanLookups: (state) => {
      return { ...state, titan: true };
    },
    triggerLibraLookups: (state) => {
      return { ...state, libra: true };
    },
    triggerScorpioLookups: (state) => {
      return { ...state, scorpio: true };
    },
  },
});

export const { updateSettings, triggerOrionLookups, triggerLibraLookups, triggerScorpioLookups, triggerTitanLookups } = settingsSlice.actions;

export default settingsSlice.reducer;
