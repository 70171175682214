import React, { useCallback, useEffect, useState } from 'react';
import { Button, DropdownFilter, Dropzone, Form, InputText } from '@cognitiv/cassiopeia-ui';
import { TitanAdvertiser, useClientContext } from '@cognitiv/galaxy-api';
import { updateOrionAdvertiserDomainListModal } from 'ducks/modals/slices';
import { DomainListFormProps } from 'products/orion/modals/manage-orion-advertiser-domain-list/types';
import { selectTitanAdvertisers } from 'products/titan/operators/advertisers/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from 'products/orion/modals/manage-orion-advertiser-domain-list/ManageOrionAdvertiserDomainList.module.scss';

export const DomainListForm = ({
  form,
  files,
  onChangeDomainList,
  onChangeConfiguration,
  onSubmit,
  entity_exists,
  segment_page,
}: DomainListFormProps) => {
  const dispatch = useAppDispatch();
  const { Titan, handleError } = useClientContext();
  const titan_advertisers = useAppSelector(selectTitanAdvertisers);
  const [advertisers, setAdvertisers] = useState<TitanAdvertiser[]>([]);

  useEffect(() => {
    setAdvertisers(titan_advertisers);
  }, [titan_advertisers]);

  const onClose = useCallback(() => {
    dispatch(updateOrionAdvertiserDomainListModal({ is_open: false }));
  }, [dispatch]);

  const onSelectAdvertiser = useCallback(
    ({ advertiser_name, advertiser_id }: TitanAdvertiser) => onChangeDomainList({ advertiser_name, advertiser_id }),
    [onChangeDomainList],
  );

  const onChangeAdvertiser = useCallback(
    async ({ value }: { value: string }) => {
      try {
        const advertisers = await Titan.getTitanAdvertisers({
          filter: value,
        });
        setAdvertisers(advertisers);
      } catch (error) {
        handleError(error);
      }
    },
    [Titan, handleError],
  );

  return (
    <Form onSubmit={onSubmit}>
      <div className={cn.body}>
        <InputText
          label="List Name"
          value={form.domain_list_name}
          onChange={({ value }) => onChangeDomainList({ domain_list_name: value })}
          auto_focus
        />
        <DropdownFilter
          label="Advertiser"
          option_key="advertiser_name"
          options={advertisers}
          debounce={300}
          value={form.advertiser_name ?? ''}
          onSelect={onSelectAdvertiser}
          onChange={onChangeAdvertiser}
          disabled={entity_exists || segment_page}
        />
        <Dropzone
          label="File"
          message="Drop or Upload a Domain List"
          onSelect={(file) => onChangeConfiguration({ files: file })}
          onDelete={() => onChangeConfiguration({ files: [] })}
          files={files}
        />
      </div>
      <div className={cn.footer}>
        <Button variant="subtle" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!files.length} type="submit">
          {entity_exists ? 'Save' : 'Create'}
        </Button>
      </div>
    </Form>
  );
};
