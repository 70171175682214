import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScorpioCampaignInsights } from 'products/scorpio/operators/campaign-insights/types';
import { SLICE } from 'products/scorpio/operators/slice';

export const initialState: ScorpioCampaignInsights[] = [];

export const ScorpioCampaignInsightsSlice = createSlice({
  name: SLICE.SCORPIO_CAMPAIGN_INSIGHTS,
  initialState,
  reducers: {
    clearScorpioCampaignInsights: () => {
      return initialState;
    },

    setScorpioCampaignInsights(_, action: PayloadAction<ScorpioCampaignInsights[]>) {
      return action.payload;
    },
  },
});

export const { clearScorpioCampaignInsights, setScorpioCampaignInsights } = ScorpioCampaignInsightsSlice.actions;

export default ScorpioCampaignInsightsSlice.reducer;
