import React from 'react';
import { CARET, Icon, Tooltip } from '@cognitiv/cassiopeia-ui';
import classNames from 'classnames';

import cn from 'components/menu/components/Components.module.scss';
import { useMenuGroupContext } from 'components/menu/components/MenuGroup';
import { menu_theme } from 'components/menu/components/theme';
import { useMenuContext } from 'components/menu/context/MenuContext';
import { MenuActionProps } from 'components/menu/types';

export const MenuAction = ({ onClick, icon, label, children, className, override = false, message = '' }: MenuActionProps) => {
  const { is_menu_open } = useMenuContext();
  const { is_expanded } = useMenuGroupContext();
  return (
    <div className={classNames(cn.action, className, { [cn.open]: is_menu_open })} onClick={onClick}>
      {icon && <Icon icon={icon} theme={menu_theme.large} />}
      {children}
      {!is_menu_open && !is_expanded && <Tooltip message={!override ? label : message} caret={CARET.LEFT} />}
      {override && <Tooltip message={message} caret={CARET.LEFT} />}
      <p className={cn.label}>{label}</p>
    </div>
  );
};
