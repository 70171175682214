import { ScorpioCampaign } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/scorpio/operators/slice';

const initialState: ScorpioCampaign[] = [];

export const scorpioCampaignsSlice = createSlice({
  name: SLICE.SCORPIO_CAMPAIGNS,
  initialState,
  reducers: {
    clearScorpioCampaigns: () => initialState,
    setScorpioCampaigns: (_, action: PayloadAction<ScorpioCampaign[]>) => action.payload,
  },
});

export const { clearScorpioCampaigns, setScorpioCampaigns } = scorpioCampaignsSlice.actions;

export default scorpioCampaignsSlice.reducer;
