import { CONDITION_TYPE, VALIDATE } from '@cognitiv/cicada-ui';

export const validation = {
  advertiser_id: {
    type: VALIDATE.NUMBER,
    conditions: [
      {
        error: 'Advertiser is required',
        type: CONDITION_TYPE.GREATER_THAN,
        values: [0],
      },
    ],
  },
  dsp_id: {
    type: VALIDATE.NUMBER,
    conditions: [
      {
        error: 'DSP is required',
        type: CONDITION_TYPE.GREATER_THAN,
        values: [0],
      },
    ],
  },
};
