export enum INSIGHT_PAGE_TYPE {
  EVENTS = 1,
  CAMPAIGNS = 2,
  MODELS = 3,
}

export enum SCORPIO_EVENT_STATE_ID {
  CONFIGURING = 1,
  TESTING = 2,
  ONLINE = 3,
  UNHEALTHY = 4,
}
