import { ScorpioModel } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { scorpio_model_default } from 'products/scorpio/operators/model/defaults';
import { SLICE } from 'products/scorpio/operators/slice';

export const initialState: ScorpioModel = { ...scorpio_model_default };

export const scorpioModelSlice = createSlice({
  name: SLICE.SCORPIO_MODEL,
  initialState,
  reducers: {
    clearScorpioModel: () => {
      return initialState;
    },
    setScorpioModel: (_, action: PayloadAction<ScorpioModel>) => {
      return action.payload;
    },
  },
});

export const { clearScorpioModel, setScorpioModel } = scorpioModelSlice.actions;

export default scorpioModelSlice.reducer;
