import { ScorpioCampaign } from '@cognitiv/galaxy-api';

export const scorpio_campaign_default: ScorpioCampaign = {
  campaign_id: 0,
  campaign_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  partner_id: 0,
  partner_name: '',
  product_type_id: 0,
  product_type_name: '',
  kpi_type_id: null,
  kpi_type_name: '',
  kpi_unit_id: null,
  kpi_unit_name: '',
  kpi_benchmark: null,
  kpi_benchmark_formatted: '',
  kpi_benchmark_abbr_formatted: '',
  daily_spend_usd: 0,
  total_spend_usd: 0,
  daily_spend_usd_formatted: '',
  total_spend_usd_formatted: '',
  campaign_state_id: 0,
  campaign_state_name: '',
  uuid: '',
};
