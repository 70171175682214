import { OrionDeal } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orion_deal_default } from 'products/orion/operators/deal/defaults';
import { SLICE } from 'products/orion/operators/slice';

const initialState: OrionDeal = orion_deal_default;

export const orionDealSlice = createSlice({
  name: SLICE.ORION_DEAL,
  initialState,
  reducers: {
    clearOrionDeal: () => initialState,
    setOrionDeal: (_, action: PayloadAction<OrionDeal>) => action.payload,
  },
});

export const { clearOrionDeal, setOrionDeal } = orionDealSlice.actions;

export default orionDealSlice.reducer;
