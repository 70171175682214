import { TitanInventorySource } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanInventorySource[] = [];

export const titanInventorySourcesListSlice = createSlice({
  name: SLICE.TITAN_INVENTORY_SOURCES_LIST,
  initialState,
  reducers: {
    clearTitanInventorySourcesList: () => {
      return initialState;
    },
    setTitanInventorySourcesList: (_, action: PayloadAction<TitanInventorySource[]>) => action.payload,
  },
});

export const { clearTitanInventorySourcesList, setTitanInventorySourcesList } = titanInventorySourcesListSlice.actions;

export default titanInventorySourcesListSlice.reducer;
