import { MANAGE_INSIGHT_COMPARISON_VIEW } from 'products/scorpio/overlays/manage-insight-comparison/types';
import { InsightsOverlay } from 'products/scorpio/overlays/types';

export const scorpio_overlay: InsightsOverlay = {
  view_insight_demographic: { is_open: false },
  manage_campaign_statistics: { is_open: false },
  view_insight_advertiser: { is_open: false },
  view_insight_report: { is_open: false },
  view_report_configuration: { is_open: false },
  manage_insight_comparison: { is_open: false, is_primary: true, view: MANAGE_INSIGHT_COMPARISON_VIEW.COMPARISON },
  scorpio_mega_menu: { is_open: false },
};
