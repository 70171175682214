import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import cn from 'components/layout/navigation/Navigation.module.scss';

export const Breadcrumb: FC<{ path?: string; label: string }> = ({ path, label }) => (
  <>
    <div className={classNames(cn.breadcrumb, { [cn.static]: !path })}>{path ? <Link to={path}>{label}</Link> : <p>{label}</p>}</div>
    <FontAwesomeIcon icon={faCaretRight} className={cn.icon} />
  </>
);
