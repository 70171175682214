import React from 'react';
import Switch from 'react-switch';
import { EMPTY_FUNCTION } from '@cognitiv/cassiopeia-ui';
import { faMoon, faSun } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'hooks/useTheme';
import { colors } from 'theme/colors';

import cn from 'components/menu/components/Components.module.scss';
import { MenuSwitchProps } from 'components/menu/types';

export const MenuSwitch = ({ checked }: MenuSwitchProps) => {
  const {
    theme: { generic },
  } = useTheme();
  return (
    <Switch
      checked={checked}
      onChange={EMPTY_FUNCTION}
      onColor={generic.on_color}
      offColor={generic.off_color}
      onHandleColor={colors.neutral[1]}
      offHandleColor={colors.neutral[1]}
      handleDiameter={14}
      checkedIcon={false}
      uncheckedIcon={false}
      checkedHandleIcon={
        <div className={cn.icon}>
          <FontAwesomeIcon icon={faMoon} className={cn.theme_icon} />
        </div>
      }
      uncheckedHandleIcon={
        <div className={cn.icon}>
          <FontAwesomeIcon icon={faSun} className={cn.theme_icon} />
        </div>
      }
      activeBoxShadow=""
      height={16}
      width={32}
    />
  );
};
