import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { scorpio_insight_default } from 'products/scorpio/operators/insights/defaults';
import { ScorpioInsight } from 'products/scorpio/operators/insights/types';
import { SLICE } from 'products/scorpio/operators/slice';

export const initialState: ScorpioInsight[] = [scorpio_insight_default];

export const scorpioInsightsSlice = createSlice({
  name: SLICE.SCORPIO_INSIGHTS,
  initialState,
  reducers: {
    clearScorpioInsights: () => {
      return initialState;
    },

    setScorpioInsights: (_, action: PayloadAction<ScorpioInsight[]>) => {
      return action.payload;
    },
  },
});

export const { clearScorpioInsights, setScorpioInsights } = scorpioInsightsSlice.actions;

export default scorpioInsightsSlice.reducer;
