import React, { FC, PropsWithChildren } from 'react';
import { faArrowRightFromBracket, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { selectUser } from 'ducks/user/selectors';
import { clearUser } from 'ducks/user/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteLocalStorage } from 'utils/storage';

import cn from 'components/layout/navigation/Navigation.module.scss';

export const Navigation: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const onClick = () => {
    try {
      dispatch(clearUser());
      deleteLocalStorage('user');
    } catch {
      throw new Error('unable to clear store');
    }
  };
  return (
    <nav className={cn.navigation}>
      {children}
      <div className={cn.grow} />
      <div className={cn.breadcrumb_name}>
        <FontAwesomeIcon icon={faUser} />
        <p>{user.display_name || 'User'}</p>
      </div>
      <div onClick={onClick} className={classNames(cn.breadcrumb, cn.link)}>
        <FontAwesomeIcon icon={faArrowRightFromBracket} />
        <p>Logout</p>
      </div>
    </nav>
  );
};
