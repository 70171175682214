import { CONDITION_TYPE, VALIDATE } from '@cognitiv/cicada-ui';

export const validation = {
  advertiser_name: {
    type: VALIDATE.STRING,
    conditions: [
      {
        error: 'Name is required',
        type: CONDITION_TYPE.MIN_LENGTH,
        values: [1],
      },
    ],
  },
  partner_id: {
    type: VALIDATE.NUMBER,
    conditions: [
      {
        error: 'Partner is required',
        type: CONDITION_TYPE.GREATER_THAN,
        values: [0],
      },
    ],
  },
};
