import { OrionDomainList } from '@cognitiv/galaxy-api';

export const orion_domain_list_default: OrionDomainList = {
  domain_list_id: 0,
  domain_list_name: '',
  domain_list_scope_id: 0,
  domian_list_scope_name: '',
  domain_list_scope_name_formatted: '',
  advertiser_id: 0,
  advertiser_name: '',
  segment_id: null,
  state_id: 0,
  state_name: '',
  state_name_formatted: '',
  domain_count: 0,
  domain_count_formatted: '',
  segment_name: '',
  uuid: '',
};
