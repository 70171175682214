import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'ducks/slice';
import { User } from 'ducks/user/types';
import { deleteLocalStorage } from 'utils/storage';

const initialState: User = {
  error: null,
};

export const userSlice = createSlice({
  name: SLICE.USER,
  initialState,
  reducers: {
    setUser: (_state, action) => {
      return action.payload;
    },
    clearUser: () => {
      deleteLocalStorage('user');
      return initialState;
    },
  },
});

export const { clearUser, setUser } = userSlice.actions;

export default userSlice.reducer;
