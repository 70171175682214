import { LibraDspIdentifier } from '@cognitiv/galaxy-api';

export const libra_dsp_identifier_default: LibraDspIdentifier = {
  dsp_name: '',
  dsp_id: 0,
  advertiser_name: '',
  advertiser_id: 0,
  advertiser_dsp_seat_id: 0,
  dsp_seat_identifier_formatted: '',
  uuid: '',
  dsp_seat_identifier: '',
  is_archived: false,
};
