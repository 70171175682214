import { OrionCampaign } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/orion/operators/slice';

const initialState: OrionCampaign[] = [];

export const orionCampaignsSlice = createSlice({
  name: SLICE.ORION_CAMPAIGNS,
  initialState,
  reducers: {
    clearOrionCampaigns: () => initialState,
    setOrionCampaigns: (_, action: PayloadAction<OrionCampaign[]>) => action.payload,
  },
});

export const { clearOrionCampaigns, setOrionCampaigns } = orionCampaignsSlice.actions;

export default orionCampaignsSlice.reducer;
