import { ScorpioInsightReport } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/scorpio/operators/slice';

const initialState: ScorpioInsightReport[] = [];

export const scorpioInsightReportsSlice = createSlice({
  name: SLICE.SCORPIO_INSIGHT_REPORTS,
  initialState,
  reducers: {
    clearScorpioInsightReports: () => initialState,
    setScorpioInsightReports: (_, action: PayloadAction<ScorpioInsightReport[]>) => action.payload,
  },
});

export const { clearScorpioInsightReports, setScorpioInsightReports } = scorpioInsightReportsSlice.actions;

export default scorpioInsightReportsSlice.reducer;
