import { OrionDomainListConfiguration } from 'products/orion/modals/manage-orion-advertiser-domain-list/types';

export const configuration_default: OrionDomainListConfiguration = {
  message: 'Contact Cognitiv',
  files: [],
  presigned_url: '',
  action: '',
  polling: false,
  count: 0,
};
