export enum SLICE {
  SCORPIO_ADVERTISERS = 'scorpio-advertisers',
  SCORPIO_CAMPAIGNS = 'scorpio-campaigns',
  SCORPIO_CAMPAIGN = 'scorpio-campaign',
  SCORPIO_EVENTS = 'scorpio-events',
  SCORPIO_EVENT = 'scorpio-event',
  SCORPIO_MODELS = 'scorpio-models',
  SCORPIO_MODEL = 'scorpio-model',
  SCORPIO_CAMPAIGN_INSIGHTS = 'scorpio-campaign-insights',
  SCORPIO_CAMPAIGN_STATISTICS = 'scorpio-campaign-statistics',
  SCORPIO_INSIGHTS = 'scorpio-insights',
  SCORPIO_INSIGHT_REPORTS = 'scorpio-insight-reports',
}
