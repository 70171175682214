import { OrionCampaign } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orion_campaign_default } from 'products/orion/operators/campaign/defaults';
import { SLICE } from 'products/orion/operators/slice';

const initialState: OrionCampaign = orion_campaign_default;

export const orionCampaignSlice = createSlice({
  name: SLICE.ORION_CAMPAIGN,
  initialState,
  reducers: {
    clearOrionCampaign: () => initialState,
    setOrionCampaign: (_, action: PayloadAction<OrionCampaign>) => action.payload,
  },
});

export const { clearOrionCampaign, setOrionCampaign } = orionCampaignSlice.actions;

export default orionCampaignSlice.reducer;
