import { OrionDomainList } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orion_domain_list_default } from 'products/orion/operators/domain-list/defaults';
import { SLICE } from 'products/orion/operators/slice';

const initialState: OrionDomainList = { ...orion_domain_list_default };

export const orionDomainListSlice = createSlice({
  name: SLICE.ORION_DOMAIN_LIST,
  initialState,
  reducers: {
    clearOrionDomainList: () => orion_domain_list_default,
    setOrionDomainList: (_, action: PayloadAction<OrionDomainList>) => action.payload,
  },
});

export const { clearOrionDomainList, setOrionDomainList } = orionDomainListSlice.actions;

export default orionDomainListSlice.reducer;
