import React, { FormEvent, useState } from 'react';
import { Button, InputText } from '@cognitiv/cassiopeia-ui';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { store } from 'store';
import { SERVICES, useClientContext, SolResetPasswordPayload } from '@cognitiv/galaxy-api';
import { selectSettings } from 'ducks/settings/selectors';
import { useParams } from 'react-router-dom';
import { normalizeUser } from 'ducks/user/normalizers';

import cn from 'products/public/pages/reset-password/ResetPasswordPage.module.scss';
import { setUser } from 'ducks/user/slices';
import { updateSettings } from 'ducks/settings/slices';

const { PUBLIC_URL } = process.env;

export interface ResetPasswordPayload extends Omit<SolResetPasswordPayload, 'authentication_token'> {
  confirm_password: string;
}

export const initial_state: ResetPasswordPayload = {
  new_password: '',
  confirm_password: '',
};

export default () => {
  const dispatch = useAppDispatch();
  const { auth_token } = useParams();
  const { Sol, persistKeys, handleError } = useClientContext();
  const { theme } = useAppSelector(selectSettings);

  const [form, setForm] = useState<ResetPasswordPayload>({ ...initial_state });

  const onChange = (item: Partial<ResetPasswordPayload>) => {
    dispatch(setUser({ error: null }));
    setForm((prev) => ({ ...prev, ...item }));
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const email_address = await Sol.resetPassword({
        authentication_token: String(auth_token),
        new_password: form.new_password,
      });

      const data = await Sol.authenticate({
        email_address,
        password: form.new_password,
        required_service_ids: [SERVICES.ORION, SERVICES.LIBRA, SERVICES.TITAN, SERVICES.SCORPIO],
      });

      persistKeys({ public_key: data.public_key, private_key: data.private_key });

      const user = normalizeUser(data);
      store.dispatch(setUser(user));
    } catch (err) {
      handleError(err);
      dispatch(updateSettings({ loading: false }));
    }
  };

  const error = form.new_password !== form.confirm_password;
  return (
    <div className={cn.page}>
      <form className={cn.login} onSubmit={onSubmit}>
        <div className={cn.logo}>
          <img src={`${PUBLIC_URL}/cogn-logo-${theme}.svg`} alt="Cognitiv Logo" />
        </div>
        <InputText
          auto_focus
          label="Password"
          type="password"
          information="Password must contain an uppercase letter, a number, and be 6 or more characters long"
          margin="32px 0px 0px 0px"
          value={form.new_password}
          onChange={(item) => onChange({ new_password: item.value })}
        />
        <InputText
          type="password"
          label="Confirm Password"
          margin="16px 0px 0px 0px"
          value={form.confirm_password}
          error={error}
          onChange={(item) => onChange({ confirm_password: item.value })}
        />
        <Button min_width="100%" type="submit" disabled={error} margin="32px 0px 0px 0px">
          Reset Password
        </Button>
      </form>
    </div>
  );
};
