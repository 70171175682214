export {};

declare global {
  interface Array<T> {
    sortObject<TKey extends keyof T>(key: TKey, direction?: 'asc' | 'desc'): T[];
  }
}

Array.prototype.sortObject = function <T, TKey extends keyof T>(key: TKey, direction: 'asc' | 'desc' = 'asc') {
  const compare = (a: T, b: T) => {
    const a_value = a[key];
    const b_value = b[key];

    if (a_value == null && b_value == null) return 0;
    if (a_value == null) return direction === 'asc' ? -1 : 1;
    if (b_value == null) return direction === 'asc' ? 1 : -1;

    if (typeof a_value === 'number' && typeof b_value === 'number') {
      return direction === 'asc' ? a_value - b_value : b_value - a_value;
    }

    const a_str = String(a_value).toLowerCase();
    const b_str = String(b_value).toLowerCase();

    if (direction === 'asc') {
      return a_str.localeCompare(b_str, 'en-US', {
        numeric: true,
        sensitivity: 'base',
      });
    }
    return b_str.localeCompare(a_str, 'en-US', {
      numeric: true,
      sensitivity: 'base',
    });
  };

  return this.sort(compare);
};
