import { OrionSegment } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orion_segment_default } from 'products/orion/operators/segment/defaults';
import { SLICE } from 'products/orion/operators/slice';

const initialState: OrionSegment = orion_segment_default;

export const orionSegmentSlice = createSlice({
  name: SLICE.ORION_SEGMENT,
  initialState,
  reducers: {
    clearOrionSegment: () => initialState,
    setOrionSegment: (_, action: PayloadAction<OrionSegment>) => action.payload,
  },
});

export const { clearOrionSegment, setOrionSegment } = orionSegmentSlice.actions;

export default orionSegmentSlice.reducer;
