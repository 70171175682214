export enum AUTHENTICATION_RESULTS {
  UNKNOWN = 0,
  SUCCESS = 1,
  INVALID_LOGIN = 2,
  ACCOUNT_INACTIVE = 3,
  ACCOUNT_LOCKED = 4,
  INVALID_PASSWORD = 5,
  INVALID_ORGANIZATION = 6,
  HASH_MISMATCH = 7,
  INVALID_PRIVATE_KEY = 8,
  NO_DEFAULT_ORGANIZATION = 9,
  SERVICE_DENIED_LOGIN = 10,
}
