import { TitanAdvertiser } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanAdvertiser[] = [];

export const titanAdvertisersSlice = createSlice({
  name: SLICE.TITAN_ADVERTISERS,
  initialState,
  reducers: {
    clearTitanAdvertisers: () => initialState,
    setTitanAdvertisers: (_, action: PayloadAction<TitanAdvertiser[]>) => action.payload,
  },
});

export const { clearTitanAdvertisers, setTitanAdvertisers } = titanAdvertisersSlice.actions;

export default titanAdvertisersSlice.reducer;
