import React, { FormEvent, useState } from 'react';
import { Action, Button, InputText } from '@cognitiv/cassiopeia-ui';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUser } from 'ducks/user/selectors';
import { UserPayload } from 'ducks/user/types';
import { store } from 'store';
import { setUser } from 'ducks/user/slices';

import cn from 'products/public/pages/login/LoginPage.module.scss';
import { SERVICES, useClientContext } from '@cognitiv/galaxy-api';
import { normalizeUser } from 'ducks/user/normalizers';
import { selectSettings } from 'ducks/settings/selectors';
import { useNavigate } from 'react-router-dom';
import { updateSettings } from 'ducks/settings/slices';

const { PUBLIC_URL } = process.env;

export const initial_state: UserPayload = {
  email_address: '',
  password: '',
};

export default () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const { theme } = useAppSelector(selectSettings);
  const { Sol, persistKeys } = useClientContext();
  const [form, setForm] = useState<UserPayload>({ ...initial_state });

  const onChange = (item: Partial<UserPayload>) => {
    dispatch(setUser({ error: null }));
    setForm((prev) => ({ ...prev, ...item }));
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const data = await Sol.authenticate({
        email_address: form.email_address,
        password: form.password,
        required_service_ids: [SERVICES.ORION, SERVICES.LIBRA, SERVICES.TITAN, SERVICES.SCORPIO],
      });
      persistKeys({ public_key: data.public_key, private_key: data.private_key });

      const user = normalizeUser(data);
      store.dispatch(setUser(user));
    } catch (err: any) {
      dispatch(updateSettings({ loading: false }));
      dispatch(setUser({ error: err?.message }));
    }
  };

  return (
    <div className={cn.page}>
      <form className={cn.login} onSubmit={onSubmit}>
        <div className={cn.logo}>
          <img src={`${PUBLIC_URL}/cogn-logo-${theme}.svg`} alt="Cognitiv Logo" />
        </div>
        <InputText
          auto_focus
          margin="32px 0px 0px 0px"
          placeholder="Username"
          value={form.email_address}
          onChange={(item) => onChange({ email_address: item.value })}
        />
        <InputText type="password" placeholder="Password" margin="16px 0px 0px 0px" onChange={(item) => onChange({ password: item.value })} />
        <Button type="submit" min_width="100%" margin="32px 0px 0px 0px">
          Log In
        </Button>
        <Action onClick={() => navigate('/request-password')} margin="16px auto 0px auto">
          Forgot password
        </Action>
        {user?.error && <p className={cn.error}>{user.error}</p>}
      </form>
    </div>
  );
};
