import { useCallback, useMemo, useState } from 'react';
import { Error, FormProps, validateForm, ValidateProps } from '@cognitiv/cicada-ui';

export const useValidate = <T extends FormProps>(data: T, validation_schema: ValidateProps) => {
  const [errors, setErrors] = useState<Error[]>([]);

  const validate = useCallback(() => {
    const errors = validateForm(data, validation_schema);
    setErrors(errors);

    return !errors.length;
  }, [data, validation_schema]);

  const resetError = useCallback(
    (item: Partial<T>) => {
      const new_errors = errors.filter((e) => !Object.keys(item).includes(e.key));
      setErrors(new_errors);
    },
    [errors],
  );

  const resetErrors = useCallback(() => setErrors([]), []);

  const disabled = useMemo(() => {
    const errors = validateForm(data, validation_schema);
    return errors.length !== 0;
  }, [data, validation_schema]);

  return { disabled, errors, validate, setErrors, resetError, resetErrors };
};
