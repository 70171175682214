import { LibraFilterAttribute } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/libra/operators/slice';

const initialState: LibraFilterAttribute[] = [];

export const libraFilterAttributesSlice = createSlice({
  name: SLICE.LIBRA_FILTER_ATTRIBUTES,
  initialState,
  reducers: {
    clearLibraFilterAttributes: () => initialState,
    setLibraFilterAttributes: (_, action: PayloadAction<LibraFilterAttribute[]>) => action.payload,
  },
});

export const { clearLibraFilterAttributes, setLibraFilterAttributes } = libraFilterAttributesSlice.actions;

export default libraFilterAttributesSlice.reducer;
