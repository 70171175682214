import React from 'react';
import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';
import { ThemeProvider, useRootTheme } from '@cognitiv/cassiopeia-ui';
import { selectSettings } from 'ducks/settings/selectors';
import { selectUserAuthentication } from 'ducks/user/selectors';
import { Confirm } from 'modals/utility/Confirm';
import { Error } from 'modals/utility/Error';
import { PUBLIC } from 'routes';
import { useAppSelector } from 'store/hooks';

import { LOADING_VARIANTS } from 'components/layout/loading/enums';
import { Loading } from 'components/layout/loading/Loading';
import { Snackbar } from 'components/layout/snackbar/Snackbar';
import { MenuContext } from 'components/menu/context/MenuContext';

import cn from 'templates/Template.module.scss';

export default () => {
  const authenticated = useAppSelector(selectUserAuthentication);
  const { loading, theme: theme_variant } = useAppSelector(selectSettings);
  useRootTheme(theme_variant, 'luna');

  if (!authenticated) {
    return <Navigate to={PUBLIC.LOGIN.path} state={{ referer: `${location.pathname}${location.search}` }} />;
  }

  return (
    <ThemeProvider theme_variant={theme_variant}>
      <MenuContext>
        <div className={cn.private}>
          <Outlet />
          <Snackbar />
          <Error />
          <Confirm />
          {loading && <Loading variant={LOADING_VARIANTS.DARK} />}
        </div>
      </MenuContext>
    </ThemeProvider>
  );
};
