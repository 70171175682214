import { ScorpioAdvertiser } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/scorpio/operators/slice';

const initialState: ScorpioAdvertiser[] = [];

export const scorpioAdvertisersSlice = createSlice({
  name: SLICE.SCORPIO_ADVERTISERS,
  initialState,
  reducers: {
    clearScorpioAdvertisers: () => initialState,
    setScorpioAdvertisers: (_, action: PayloadAction<ScorpioAdvertiser[]>) => action.payload,
  },
});

export const { clearScorpioAdvertisers, setScorpioAdvertisers } = scorpioAdvertisersSlice.actions;

export default scorpioAdvertisersSlice.reducer;
