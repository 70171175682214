// DEVNOTE: we need this list to have ability connecting counties and state from api to polygons
import geographics from '_data/united_states_topology.json';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBrainCircuit, faBullhorn, faDisplayCode } from '@fortawesome/pro-duotone-svg-icons';
import * as ChartGeo from 'chartjs-chart-geo';
import { INSIGHT_PAGE_TYPE, SCORPIO_EVENT_STATE_ID } from 'products/scorpio/enums';
import { STATE_THEME } from 'theme/theme';
import { createEnum } from 'utils/enums';
export const INSIGHT_PAGE_TYPE_NAME: Record<INSIGHT_PAGE_TYPE, string> = {
  [INSIGHT_PAGE_TYPE.EVENTS]: 'Events',
  [INSIGHT_PAGE_TYPE.CAMPAIGNS]: 'Campaigns',
  [INSIGHT_PAGE_TYPE.MODELS]: 'Models',
};

export const INSIGHT_PAGE_ICON: Record<INSIGHT_PAGE_TYPE, IconProp> = {
  [INSIGHT_PAGE_TYPE.EVENTS]: faDisplayCode,
  [INSIGHT_PAGE_TYPE.CAMPAIGNS]: faBullhorn,
  [INSIGHT_PAGE_TYPE.MODELS]: faBrainCircuit,
};

export const insight_pages: { label: string; id: number }[] = [
  {
    label: INSIGHT_PAGE_TYPE_NAME[INSIGHT_PAGE_TYPE.EVENTS],
    id: INSIGHT_PAGE_TYPE.EVENTS,
  },
  {
    label: INSIGHT_PAGE_TYPE_NAME[INSIGHT_PAGE_TYPE.CAMPAIGNS],
    id: INSIGHT_PAGE_TYPE.CAMPAIGNS,
  },
  {
    label: INSIGHT_PAGE_TYPE_NAME[INSIGHT_PAGE_TYPE.MODELS],
    id: INSIGHT_PAGE_TYPE.MODELS,
  },
];

export interface CampaignQuery {
  statistics_start_date?: string;
  statistics_finish_date?: string;
  group_by_line_item?: boolean;
  campaign_name?: boolean;
  group_by_creative?: boolean;
  group_by_date?: boolean;
  impressions?: boolean;
  clicks?: boolean;
  conversions?: boolean;
  spend?: boolean;
  ctr?: boolean;
  cpa?: boolean;
  cvr?: boolean;
}

export enum REPORT_TYPE {
  CSV = 'CSV',
  PDF = 'PDF',
  PPT = 'PPT',
}

export enum VISUALIZATION_COLOR {
  GRADIENT = 'Gradient',
  PURPLE = 'Purple',
  TEAL = 'Teal',
  BLUE = 'Blue',
}

export enum LEGEND_TYPE {
  CPI = 'CPI',
  DEMOGRAPHIC = 'Demographics',
  PERFORMANCE = 'Performance',
  MODEL = 'Model',
  FEATURE = 'Feature',
  NONE = '',
}

export enum INSIGHT_PATH {
  DEMOGRAPHICS = 'demographics',
  KEYWORDS = 'keywords',
  DOMAINS = 'domains',
  GEOGRAPHICS = 'geographics',
  TEMPORALS = 'temporals',
  PREDICTIONS = 'predictions',
  FEATURES = 'features',
  STATISTICS = 'statistics',
  USER_JOURNEYS = 'user-journeys',
}

export enum DEMOGRAPHIC_TYPE {
  GENDER = 'Gender',
  AGE = 'Age',
  HOME_TYPE = 'Home Type',
  EDUCATION = 'Education',
  HOME_OWNERSHIP = 'Home Ownership',
  OCCUPATION = 'Occupation',
  INCOME = 'Annual Household Income',
  MARITIAL_STATUS = 'Maritial Status',
  CHILDREN = 'Children',
}

export enum INSIGHT_TYPE {
  DEMOGRAPHICS = 'Demographics',
  KEYWORDS = 'Keyword',
  DOMAINS = 'Domain',
  GEOGRAPHICS = 'Geographic',
  TEMPORALS = 'Temporal',
  STATISTICS = 'Statistics',
  PREDICTIONS = 'Predictions',
  PREDICTION_FEATURES = 'Prediction Features',
  USER_JOURNEY = 'User Journey',
}

export enum KPI_INSIGHT_TYPE {
  CPI = 'CPI',
  CPI_HOUR = 'CPI Hour',
  CPI_DAY = 'CPI Day',
  DELIVERY = 'Delivery',
  DELIVERY_HOUR = 'Delivery Hour',
  DELIVERY_DAY = 'Delivery Day',
  PERFORMANCE = 'Performance',
  PERFORMANCE_HOUR = 'Performance Hour',
  PERFORMANCE_DAY = 'Performance Day',
  DEFAULT = 'Default',
  AWARENESS = 'Awareness',
  CONSIDERATION = 'Consideration',
  PURCHASE_INTENT = 'Purchase Intent',
  CONVERSION = 'Conversion',
}

export enum TOOLTIP_TYPE {
  CPI = 'CPI',
}

export const INSIGHT_TOOLTIP = {
  [TOOLTIP_TYPE.CPI]: 'Conversion Probability Index is a performance indicator which refers to the likelihood of a conversion.',
};

export enum KPI_TYPE {
  CONVERSIONS = 'conversions',
  ROAS = 'roas',
  CPA = 'cpa',
  CVR = 'cvr',
  CTR = 'ctr',
}

export const KPI_TYPE_LABEL = {
  [KPI_TYPE.CONVERSIONS]: 'Conversions',
  [KPI_TYPE.ROAS]: 'ROAS',
  [KPI_TYPE.CPA]: 'CPA',
  [KPI_TYPE.CVR]: 'CVR',
  [KPI_TYPE.CTR]: 'CTR',
};

export const KPI_TYPE_KEY = {
  [KPI_TYPE.CONVERSIONS]: 'conversions',
  [KPI_TYPE.ROAS]: 'roas_usd',
  [KPI_TYPE.CPA]: 'cpa_usd',
  [KPI_TYPE.CVR]: 'conversions_rate',
  [KPI_TYPE.CTR]: 'ctr',
};

const KPI_TYPE_MAP = createEnum(KPI_TYPE);
const REPORT_TYPE_MAP = createEnum(REPORT_TYPE);
const LEGEND_TYPE_MAP = createEnum(LEGEND_TYPE);

export type KpiType = keyof typeof KPI_TYPE_MAP;
export type ReportType = keyof typeof REPORT_TYPE_MAP;
export type LegendType = keyof typeof LEGEND_TYPE_MAP;

export const TOOLTIPS = {
  [KPI_INSIGHT_TYPE.CPI]:
    'Conversion probability index is a performance indicator which refers to likelihood of conversion. CPI > 1 means higher chance while CPI < 1 means a lower chance to convert.',
  [KPI_INSIGHT_TYPE.CPI_DAY]:
    'Conversion probability index is a performance indicator which refers to likelihood of conversion. CPI > 1 means higher chance while CPI < 1 means a lower chance to convert.',
  [KPI_INSIGHT_TYPE.CPI_HOUR]:
    'Conversion probability index is a performance indicator which refers to likelihood of conversion. CPI > 1 means higher chance while CPI < 1 means a lower chance to convert.',
  [KPI_INSIGHT_TYPE.DELIVERY]:
    'Conversion probability index is a performance indicator which refers to likelihood of conversion. Impressions > 1 means higher chance while Impressions < 1 means a lower chance to convert.',
  [KPI_INSIGHT_TYPE.AWARENESS]: 'Users exposed to placed creatives. Awareness will always be 100%.',
  [KPI_INSIGHT_TYPE.CONSIDERATION]: 'Users exposed to website. Consideration is usually landing page / home page.',
  [KPI_INSIGHT_TYPE.PURCHASE_INTENT]:
    'Users showing interest in product or offerings but haven’t converted. Purchase Intent is usually a product page.',
  [KPI_INSIGHT_TYPE.CONVERSION]: 'Users have converted. Conversion is usually a checkout page.',
};

export const kpi_options = [
  {
    kpi_label: KPI_TYPE_LABEL[KPI_TYPE.CONVERSIONS],
    kpi_type: KPI_TYPE.CONVERSIONS,
    uuid: 1,
  },
  {
    kpi_label: KPI_TYPE_LABEL[KPI_TYPE.ROAS],
    kpi_type: KPI_TYPE.ROAS,
    uuid: 2,
  },
  {
    kpi_label: KPI_TYPE_LABEL[KPI_TYPE.CPA],
    kpi_type: KPI_TYPE.CPA,
    uuid: 3,
  },
  {
    kpi_label: KPI_TYPE_LABEL[KPI_TYPE.CVR],
    kpi_type: KPI_TYPE.CVR,
    uuid: 4,
  },
  {
    kpi_label: KPI_TYPE_LABEL[KPI_TYPE.CTR],
    kpi_type: KPI_TYPE.CTR,
    uuid: 5,
  },
];

export const CLUSTER_COLOR = {
  0: 'rgba(32, 175, 157, 1)',
  1: 'rgba(39, 110, 241, 1)',
  2: 'rgba(255, 105, 55, 1)',
  3: 'rgba(255, 207, 1, 1)',
  4: 'rgba(115, 86, 191, 1)',
  5: 'rgba(236, 79, 146, 1)',
  6: 'rgba(83, 112, 166, 1)',
  7: 'rgba(5, 148, 79, 1)',
  8: 'rgba(89, 225, 207, 1)',
  9: 'rgba(143, 163, 173, 1)',
  10: 'rgba(225, 25, 0, 1)',
};

export const PREDICTION_COLOR = {
  0: 'rgba(32, 175, 157, 0.1)',
  1: 'rgba(39, 110, 241, 0.1)',
  2: 'rgba(255, 105, 55, 0.1)',
  3: 'rgba(255, 207, 1, 0.1)',
  4: 'rgba(115, 86, 191, 0.1)',
  5: 'rgba(236, 79, 146, 0.1)',
  6: 'rgba(83, 112, 166, 0.1)',
  7: 'rgba(5, 148, 79, 0.1)',
  8: 'rgba(89, 225, 207, 0.1)',
  9: 'rgba(143, 163, 173, 0.1)',
  10: 'rgba(225, 25, 0, 0.1)',
};

export const stateList = {
  Arizona: 'AZ',
  Alabama: 'AL',
  Alaska: 'AK',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};
export const getStateTwoDigitCode = function (stateFullName: string) {
  return String(stateList[stateFullName]);
};

export const not_needed_states = [60, 69, 11, 66, 72, 78];

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const [united_states_json] = ChartGeo.topojson.feature(geographics, geographics.objects.nation).features;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const states_json = ChartGeo.topojson.feature(geographics, geographics.objects.states).features;

export const top_50_states = states_json.filter((state: any) => !not_needed_states.includes(+state.id));

export const SCORPIO_EVENT_STATE_COLOR = {
  [SCORPIO_EVENT_STATE_ID.CONFIGURING]: STATE_THEME.configuring,
  [SCORPIO_EVENT_STATE_ID.TESTING]: STATE_THEME.testing,
  [SCORPIO_EVENT_STATE_ID.ONLINE]: STATE_THEME.online,
  [SCORPIO_EVENT_STATE_ID.UNHEALTHY]: STATE_THEME.unhealthy,
};
