export enum MANAGE_INSIGHT_COMPARISON_VIEW {
  COMPARISON = 'comparison',
  MORE_OPTIONS = 'more-options',
}

export interface InsightReport {
  entity_id?: number;
  entity_name?: string;
  aggregation_type_id?: number;
  start_date?: string;
  finish_date?: string;
}

export interface AggregateTypeOption extends Omit<InsightReport, 'entity_id' | 'entity_name'> {
  report_name: string;
  uuid: string;
}

export interface AggregationTypeOption extends Omit<InsightReport, 'entity_id' | 'entity_name'> {
  aggregation_type: number;
  label: string;
}

export interface InsightEntity {
  entity_id: number;
  entity_name: string;
}
