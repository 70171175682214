import { TitanPartner } from '@cognitiv/galaxy-api';
import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanPartner[] = [];

export const titanPartnersSlice = createSlice({
  name: SLICE.TITAN_PARTNERS,
  initialState,
  reducers: {
    clearTitanPartners: () => initialState,
    setTitanPartners: (_, action) => action.payload,
  },
});

export const { clearTitanPartners, setTitanPartners } = titanPartnersSlice.actions;

export default titanPartnersSlice.reducer;
