type Storage = 'user' | 'settings' | 'confirm_orion_relevance' | 'confirm_reporting_report_type' | 'confirm_hyperion_report_type';
type Store = { [key: string]: any } | any[] | boolean;

export const getLocalStorage = (item: Storage = 'user') => {
  let store = null;
  const stored_item = localStorage.getItem(item);
  if (stored_item) {
    store = JSON.parse(stored_item);
  }
  if (store) {
    return store;
  }
  return {};
};

export const setLocalStorage = (item: Storage = 'user', store: Store) => {
  const data = JSON.stringify(store);
  localStorage.setItem(item, data);
};

export const deleteLocalStorage = (item: Storage = 'user') => {
  localStorage.setItem(item, '{}');
};
