import { OrionSegment } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/orion/operators/slice';

const initialState: OrionSegment[] = [];

export const orionSegmentsSlice = createSlice({
  name: SLICE.ORION_SEGMENTS,
  initialState,
  reducers: {
    clearOrionSegments: () => initialState,
    setOrionSegments: (_, action: PayloadAction<OrionSegment[]>) => action.payload,
  },
});

export const { clearOrionSegments, setOrionSegments } = orionSegmentsSlice.actions;

export default orionSegmentsSlice.reducer;
