import { OrionDomainList } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/orion/operators/slice';

const initialState: OrionDomainList[] = [];

export const orionDomainListsSlice = createSlice({
  name: SLICE.ORION_DOMAIN_LIST,
  initialState,
  reducers: {
    clearOrionDomainLists: () => [],
    setOrionDomainLists: (_, action: PayloadAction<OrionDomainList[]>) => action.payload,
  },
});

export const { clearOrionDomainLists, setOrionDomainLists } = orionDomainListsSlice.actions;

export default orionDomainListsSlice.reducer;
