import { OrionSegment } from '@cognitiv/galaxy-api';
import { EMBEDDING_VERSION } from 'products/orion/pages/segment/constants';
import { ORION_PREVIEW_SORT } from 'products/orion/pages/segment/enums';

export const orion_segment_default: OrionSegment = {
  segment_id: 0,
  segment_name: '',
  campaign_id: 0,
  campaign_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  segment_type_id: 0,
  segment_type_name: '',
  state_id: 0,
  state_name: '',
  created_date: '',
  created_by: '',
  current_segment_version_id: null,
  last_updated_date: '',
  url_count: null,
  created_by_user_id: 0,
  embedding_filter_id: EMBEDDING_VERSION,
  embedding_name: 'GPT',
  embedding_id: 1,
  weighted_relevance: 0,
  avails: 0,
  deal_count: 0,
  spend_value: null,
  preview_order_by_id: ORION_PREVIEW_SORT.RELEVANT,
  preview_order_by_name: 'Most Relevant',
  preview_order_ascending: true,
  deal_count_formatted: '',
  url_count_formatted: '',
  weighted_relevance_formatted: '',
  avails_formatted: '',
  spend_value_formatted: '',
  uuid: '',
  creative_type_id: null,
  creative_type_name: '(All)',
  device_type_id: null,
  device_type_name: '(All)',
  data_center_id: 0,
  data_center_name: '',
};
