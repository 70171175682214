import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { createSlice } from '@reduxjs/toolkit';
import { Overlays } from 'ducks/overlays/types';
import { SLICE } from 'ducks/slice';
import { scorpio_overlay } from 'products/scorpio/overlays/constants';
import { colors } from 'theme/colors';

const initialState: Overlays = {
  ...scorpio_overlay,
  snackbar: {
    is_open: false,
    title: 'Confirm Action',
    description: 'Please confirm action before proceeding.',
    icon: null,
    action: null,
    duration: 0,
    color_primary: null,
  },
};

export const overlaysSlice = createSlice({
  name: SLICE.OVERLAYS,
  initialState,
  reducers: {
    updateOverlay: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateSnackbar: (state, action) => {
      return {
        ...state,
        snackbar: {
          is_open: true,
          icon: faCheckCircle,
          duration: 4000,
          color_primary: colors.blue[6],
          ...action.payload,
        },
      };
    },
  },
});

export const { updateSnackbar, updateOverlay } = overlaysSlice.actions;

export default overlaysSlice.reducer;
