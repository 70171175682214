import orionCampaignSlice from 'products/orion/operators/campaign/slices';
import orionCampaignsSlice from 'products/orion/operators/campaigns/slices';
import orionDealSlice from 'products/orion/operators/deal/slices';
import orionDealsSlice from 'products/orion/operators/deals/slices';
import orionDomainListSlice from 'products/orion/operators/domain-list/slices';
import orionDomainListsSlice from 'products/orion/operators/domain-lists/slices';
import orionSegmentSlice from 'products/orion/operators/segment/slices';
import orionSegmentsSlice from 'products/orion/operators/segments/slices';

export const orion = {
  orion_campaigns: orionCampaignsSlice,
  orion_campaign: orionCampaignSlice,
  orion_segments: orionSegmentsSlice,
  orion_segment: orionSegmentSlice,
  orion_deals: orionDealsSlice,
  orion_deal: orionDealSlice,
  orion_domain_lists: orionDomainListsSlice,
  orion_domain_list: orionDomainListSlice,
};
