import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { App } from 'App';
import { Chart as ChartJS, registerables } from 'chart.js';
import LineAnnotation from 'chartjs-plugin-annotation';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';

import 'utils/prototype';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'scss/index.scss';
import '@cognitiv/cassiopeia-ui/dist/index.css';

declare global {
  interface ProcessEnv {
    REACT_APP_ENVIRONMENT: 'development' | 'staging' | 'production';
  }
}

ChartJS.register(...registerables, LineAnnotation);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
global.store = store;

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
