import React from 'react';
import { Outlet } from 'react-router';
import { ManageOrionAdvertiserDomainList } from 'products/orion/modals/manage-orion-advertiser-domain-list/ManageOrionAdvertiserDomainList';
import useOrionFetch from 'products/orion/template/hooks/useOrionFetch';

import { Content } from 'components/layout/content/Content';
import { Menu } from 'components/menu/Menu';

import cn from 'products/orion/template/ApplicationTemplate.module.scss';

export default () => {
  useOrionFetch();

  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Outlet />
      </Content>
      <ManageOrionAdvertiserDomainList />
    </div>
  );
};
