import { createSelector } from '@reduxjs/toolkit';
import { Overlays } from 'ducks/overlays/types';
import { RootState } from 'store';

const overlaysSlice = (state: RootState) => state.overlays;

export const selectSnackbar = createSelector([overlaysSlice], (state): any | undefined => state.snackbar);

export const selectOverlay = <T extends keyof Overlays>(state: RootState, key: T) =>
  createSelector(overlaysSlice, (modals) => modals[key] as Overlays[T])(state);
