export enum CAMPAIGN_DELIVERY_INSIGHT_TITLE {
  DEMOGRAPHICS = 'Demographics by Impressions',
  DOMAINS = 'Top Domains by Impressions{{ENTITY_NAME}}',
  GEOGRAPHICS = 'Regions by Impressions',
  TEMPORALS = 'Temporal Trends by Impressions',
  STATISTICS = 'Campaign Statistics by Delivery',
}

export enum CAMPAIGN_DELIVERY_INSIGHT_DESCRIPTION {
  DEMOGRAPHICS = 'Impression trends over {{WINDOW}}',
  DOMAINS = 'Impression trends over {{WINDOW}}',
  GEOGRAPHICS = 'Regions shaded by impressions over {{WINDOW}}',
  TEMPORALS = 'Impressions aggregated by {{TIME}} over {{WINDOW}}',
  STATISTICS = 'Delivery and performance stats for your campaign',
}

export enum CAMPAIGN_PERFORMANCE_INSIGHT_TITLE {
  DEMOGRAPHICS = 'Demographics by Conversions',
  DOMAINS = 'Top Domains by Conversions{{ENTITY_NAME}}',
  GEOGRAPHICS = 'Regions by Conversions',
  TEMPORALS = 'Temporal Trends by Conversions',
}

export enum CAMPAIGN_PERFORMANCE_INSIGHT_DESCRIPTION {
  DEMOGRAPHICS = 'Conversion trends over {{WINDOW}}',
  DOMAINS = 'Conversion trends over {{WINDOW}}',
  GEOGRAPHICS = 'Regions shaded by conversions  over {{WINDOW}}',
  TEMPORALS = 'Conversions aggregated by {{TIME}} over {{WINDOW}}',
  STATISTICS = 'Delivery and performance stats for your campaign',
}
