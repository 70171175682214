import { LibraDspIdentifier } from '@cognitiv/galaxy-api';
import { createSlice } from '@reduxjs/toolkit';
import { libra_dsp_identifier_default } from 'products/libra/operators/dsp-identifier/default';
import { SLICE } from 'products/libra/operators/slice';

const initialState: LibraDspIdentifier = libra_dsp_identifier_default;

export const libraDspIdentifierSlice = createSlice({
  name: SLICE.LIBRA_DSP_IDENTIFIER,
  initialState,
  reducers: {
    clearLibraDspIdentifier: () => {
      return initialState;
    },
    setLibraDspIdentifier: (_, action) => {
      return action.payload;
    },
  },
});

export const { clearLibraDspIdentifier, setLibraDspIdentifier } = libraDspIdentifierSlice.actions;

export default libraDspIdentifierSlice.reducer;
