import React, { FormEvent, useState } from 'react';
import { Action, Button, InputText } from '@cognitiv/cassiopeia-ui';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUser } from 'ducks/user/selectors';
import { useClientContext, SolRequestResetPayload } from '@cognitiv/galaxy-api';
import { selectSettings } from 'ducks/settings/selectors';
import { useNavigate } from 'react-router-dom';

import cn from 'products/public/pages/request-password/RequestPasswordPage.module.scss';
import { updateModal } from 'ducks/modals/slices';
import { updateSettings } from 'ducks/settings/slices';
import { setUser } from 'ducks/user/slices';

const { PUBLIC_URL } = process.env;

export const initial_state: SolRequestResetPayload = {
  email_address: '',
};

export default () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Sol } = useClientContext();
  const user = useAppSelector(selectUser);
  const { theme } = useAppSelector(selectSettings);

  const [form, setForm] = useState<SolRequestResetPayload>({ ...initial_state });

  const onChange = (item: Partial<SolRequestResetPayload>) => {
    dispatch(setUser({ error: null }));
    setForm((prev) => ({ ...prev, ...item }));
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const res = await Sol.requestPasswordReset({ email_address: form.email_address });

      if (res) {
        dispatch(
          updateModal({
            confirm: {
              is_open: true,
              title: `Email Sent`,
              message: `Please check your email for a message from noreply@cognitiv.ai and click on the provided link to enter a new password.`,
              label: 'Ok',
              onConfirm: () => {},
            },
          }),
        );
      }
    } catch (err: any) {
      dispatch(updateSettings({ loading: false }));
      dispatch(setUser({ error: 'An error occurred. Please try again.' }));
    }
  };

  return (
    <div className={cn.page}>
      <form className={cn.login} onSubmit={onSubmit}>
        <div className={cn.logo}>
          <img src={`${PUBLIC_URL}/cogn-logo-${theme}.svg`} alt="Cognitiv Logo" />
        </div>
        <p className={cn.text}>
          Enter your email address. If a matching account is found, we'll send you an email with a link to reset your password.
        </p>
        <InputText
          auto_focus
          margin="16px 0px"
          placeholder="Email Address"
          value={form.email_address}
          onChange={(item) => onChange({ email_address: item.value })}
        />
        <Button min_width="100%" type="submit" margin="32px 0px 0px 0px">
          Submit
        </Button>
        <Action variant="plain" onClick={() => navigate('/login')} margin="16px auto 0px auto">
          Cancel
        </Action>
        {user?.error && <p className={cn.error}>{user.error}</p>}
      </form>
    </div>
  );
};
