import { ScorpioCampaign } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { scorpio_campaign_default } from 'products/scorpio/operators/campaign/defaults';
import { SLICE } from 'products/scorpio/operators/slice';

export const initialState: ScorpioCampaign = { ...scorpio_campaign_default };

export const scorpioCampaignSlice = createSlice({
  name: SLICE.SCORPIO_CAMPAIGN,
  initialState,
  reducers: {
    clearScorpioCampaign: () => {
      return initialState;
    },
    setScorpioCampaign: (_, action: PayloadAction<ScorpioCampaign>) => {
      return action.payload;
    },
  },
});

export const { clearScorpioCampaign, setScorpioCampaign } = scorpioCampaignSlice.actions;

export default scorpioCampaignSlice.reducer;
