import { ScorpioModel } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/scorpio/operators/slice';

const initialState: ScorpioModel[] = [];

export const scorpioModelsSlice = createSlice({
  name: SLICE.SCORPIO_MODELS,
  initialState,
  reducers: {
    clearScorpioModels: () => initialState,
    setScorpioModels: (_, action: PayloadAction<ScorpioModel[]>) => action.payload,
  },
});

export const { clearScorpioModels, setScorpioModels } = scorpioModelsSlice.actions;

export default scorpioModelsSlice.reducer;
