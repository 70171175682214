import scorpioAdvertisersSlice from 'products/scorpio/operators/advertisers/slice';
import scorpioCampaignSlice from 'products/scorpio/operators/campaign/slice';
import scorpioInsightCampaignSlice from 'products/scorpio/operators/campaign-insights/slice';
import scorpioCampaignStatisticsSlice from 'products/scorpio/operators/campaign-statistics/slice';
import scorpioCampaignsSlice from 'products/scorpio/operators/campaigns/slice';
import scorpioEventSlice from 'products/scorpio/operators/event/slice';
import scorpioEventsSlice from 'products/scorpio/operators/events/slice';
import scorpioInsightReportsSlice from 'products/scorpio/operators/insight-reports/slice';
import scorpioInsightsSlice from 'products/scorpio/operators/insights/slice';
import scorpioModelSlice from 'products/scorpio/operators/model/slice';
import scorpioModelsSlice from 'products/scorpio/operators/models/slice';

export const scorpio = {
  scorpio_advertisers: scorpioAdvertisersSlice,
  scorpio_campaigns: scorpioCampaignsSlice,
  scorpio_campaign: scorpioCampaignSlice,
  scorpio_events: scorpioEventsSlice,
  scorpio_event: scorpioEventSlice,
  scorpio_models: scorpioModelsSlice,
  scorpio_model: scorpioModelSlice,
  scorpio_campaign_insights: scorpioInsightCampaignSlice,
  scorpio_campaign_statistics: scorpioCampaignStatisticsSlice,
  scorpio_insights: scorpioInsightsSlice,
  scorpio_insight_reports: scorpioInsightReportsSlice,
};
