import { useEffect } from 'react';
import { batch } from 'react-redux';
import { useClientContext } from '@cognitiv/galaxy-api';
import { selectSettings } from 'ducks/settings/selectors';
import { setTitanAdvertisers } from 'products/titan/operators/advertisers/slices';
import { setTitanCreativeTypesList } from 'products/titan/operators/creative-types-list/slices';
import { setTitanInventorySourcesList } from 'products/titan/operators/inventory-sources-list/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const useOrionFetch = () => {
  const dispatch = useAppDispatch();
  const { Libra, Titan, handleError } = useClientContext();
  const { orion } = useAppSelector(selectSettings);

  useEffect(() => {
    const getData = async () => {
      try {
        const [titan_inventory_sources, titan_advertisers, titan_creative_types_list] = await Promise.all([
          Titan.getTitanInventorySourcesList(),
          Titan.getTitanAdvertisers(),
          Titan.getTitanCreativeTypesList(),
        ]);

        batch(() => {
          dispatch(setTitanInventorySourcesList(titan_inventory_sources));
          dispatch(setTitanAdvertisers(titan_advertisers));
          dispatch(setTitanCreativeTypesList(titan_creative_types_list));
        });
      } catch (err) {
        handleError(err);
      }
    };
    if (orion) {
      getData();
    }
  }, [dispatch, orion, Libra, Titan, handleError]);
};

export default useOrionFetch;
