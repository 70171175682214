import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, DropdownFilter, Form, InputText, Modal } from '@cognitiv/cassiopeia-ui';
import { getValidationError, mergeSearchQuery } from '@cognitiv/cicada-ui';
import { LibraDspIdentifier, TitanAdvertiser, useClientContext } from '@cognitiv/galaxy-api';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { updateSettings } from 'ducks/settings/slices';
import { useValidate } from 'hooks/useValidate';
import { validation } from 'products/libra/modals/manage-libra-dsp-identifier/validation';
import { libra_dsp_identifier_default } from 'products/libra/operators/dsp-identifier/default';
import { selectLibraDspIdentifier } from 'products/libra/operators/dsp-identifier/selectors';
import { selectTitanAdvertisers } from 'products/titan/operators/advertisers/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { uuidv4 } from 'utils/uuid';

import cn from 'products/libra/modals/manage-libra-dsp-identifier/ManageLibraDspIdentifier.module.scss';

export const ADFORM_DSP_SEAT_ID = 15;
export const ADFORM_DSP_SEAT_NAME = 'AdForm';

export const ManageLibraDspIdentifier = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { Libra, Titan, Orion, handleError } = useClientContext();

  const { is_open } = useAppSelector((state) => selectModal(state, 'manage_libra_dsp_identifier'));
  const libra_dsp_identifier = useAppSelector(selectLibraDspIdentifier);
  const titan_advertisers = useAppSelector(selectTitanAdvertisers);

  const [form, setForm] = useState<LibraDspIdentifier>({ ...libra_dsp_identifier_default });
  const [advertisers, setAdvertisers] = useState<TitanAdvertiser[]>([]);

  const { errors, validate, resetErrors, resetError } = useValidate(form, validation);

  useEffect(() => {
    setAdvertisers(titan_advertisers);
    setForm({
      ...libra_dsp_identifier,
      dsp_name: libra_dsp_identifier.dsp_name || ADFORM_DSP_SEAT_NAME,
      dsp_id: libra_dsp_identifier.dsp_id || ADFORM_DSP_SEAT_ID,
    });
  }, [libra_dsp_identifier, titan_advertisers]);

  const onChange = useCallback(
    (item: Partial<LibraDspIdentifier>) => {
      resetError(item);
      setForm((prev) => ({ ...prev, ...item }));
    },
    [resetError],
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const is_valid = validate();

      if (!is_valid) return;

      const queries = mergeSearchQuery(search, { update: uuidv4() });

      dispatch(updateSettings({ loading: true }));

      const { advertiser_dsp_seat_id, advertiser_id, dsp_id, dsp_seat_identifier } = form;

      const payload = {
        advertiser_id,
        dsp_id,
        dsp_seat_identifier: dsp_seat_identifier,
      };

      if (entity_exists) {
        await Libra.updateLibraDspIdentifier(advertiser_dsp_seat_id, payload);
      }
      if (!entity_exists) {
        await Libra.createLibraDspIdentifier(payload);
        await Orion.getOrionSynchronizeLibraDspIdentifiers();
      }

      navigate(`${pathname}${queries}`);
      onClose();
    } catch (err) {
      handleError(err);
    }
  };

  const onClose = useCallback(() => {
    resetErrors();
    dispatch(updateModal({ manage_libra_dsp_identifier: { is_open: false } }));
  }, [dispatch, resetErrors]);

  const onSelectAdvertiser = useCallback(
    (option: TitanAdvertiser) => onChange({ advertiser_name: option.advertiser_name, advertiser_id: option.advertiser_id }),
    [onChange],
  );

  const onChangeAdvertiser = useCallback(
    async ({ value }: { value: string }) => {
      try {
        const advertisers = await Titan.getTitanAdvertisers({
          filter: value,
        });
        setAdvertisers(advertisers);
      } catch (error) {
        handleError(error);
      }
    },
    [Titan, handleError],
  );

  const entity_exists = !!form.advertiser_dsp_seat_id;

  return (
    <Modal
      title={entity_exists ? 'Update DSP Identifier' : 'Create DSP Identifier'}
      identifier="manage_libra_dsp_identifier"
      is_open={is_open}
      width={440}
      onClose={onClose}
    >
      <Form onSubmit={onSubmit}>
        <div className={cn.body}>
          <DropdownFilter
            auto_focus
            disabled={entity_exists}
            debounce={300}
            label="Advertiser"
            option_key="advertiser_name"
            options={advertisers}
            value={form.advertiser_name}
            onSelect={onSelectAdvertiser}
            onChange={onChangeAdvertiser}
            error={getValidationError<LibraDspIdentifier>({ errors, key: 'advertiser_id' })}
          />
          <InputText label="DSP" margin="16px 0px 0px" disabled value={form.dsp_name} />
          <InputText
            margin="16px 0px 0px"
            label="DSP Seat Identifier"
            value={form.dsp_seat_identifier}
            onChange={(data: { value: string }) => onChange({ dsp_seat_identifier: data.value })}
            error={getValidationError<LibraDspIdentifier>({ errors, key: 'dsp_seat_identifier' })}
          />
        </div>
        <div className={cn.footer}>
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">{entity_exists ? 'Save' : 'Create'}</Button>
        </div>
      </Form>
    </Modal>
  );
};
