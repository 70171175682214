import { TitanAdvertiser } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_advertiser_default } from 'products/titan/operators/advertiser/defaults';
import { SLICE } from 'products/titan/operators/slice';

export const initialState: TitanAdvertiser = {
  ...titan_advertiser_default,
};

export const titanAdvertiserSlice = createSlice({
  name: SLICE.TITAN_ADVERTISER,
  initialState,
  reducers: {
    clearTitanAdvertiser: () => {
      return initialState;
    },
    setTitanAdvertiser(_, action: PayloadAction<TitanAdvertiser>) {
      return action.payload;
    },
  },
});

export const { clearTitanAdvertiser, setTitanAdvertiser } = titanAdvertiserSlice.actions;

export default titanAdvertiserSlice.reducer;
