import { ScorpioEventSource } from '@cognitiv/galaxy-api';

export const scorpio_event_default: ScorpioEventSource = {
  advertiser_id: 0,
  advertiser_name: '',
  event_source_id: 0,
  event_source_name: '',
  event_source_identifier: '',
  event_source_type_id: 0,
  event_source_type_name: '',
  event_source_category_id: null,
  event_source_category_name: '',
  event_source_state_id: 0,
  event_source_state_name: '',
  today_count: 0,
  yesterday_count: null,
  this_week_count: null,
  this_month_count: null,
  all_time_count: null,
  daily_average_count: null,
  weekly_average_count: null,
  monthly_average_count: null,
  event_source_name_formatted: '',
  today_count_formatted: '',
  yesterday_count_formatted: '',
  this_week_count_formatted: '',
  this_month_count_formatted: '',
  all_time_count_formatted: '',
  daily_average_count_formatted: '',
  weekly_average_count_formatted: '',
  monthly_average_count_formatted: '',
  uuid: '',
};
