import {
  ScorpioInsight,
  ScorpioInsightDemographics,
  ScorpioInsightDomains,
  ScorpioInsightFeatures,
  ScorpioInsightGeographics,
  ScorpioInsightKeywords,
  ScorpioInsightPredictions,
  ScorpioInsightTemporal,
  ScorpioInsightTemporalData,
  ScorpioInsightUserJourneys,
} from 'products/scorpio/operators/insights/types';

export const scorpio_insight_demographics_default: ScorpioInsightDemographics = {
  title: '',
  description: '',
  age: [],
  children: [],
  education: [],
  gender: [],
  home_ownership: [],
  home_type: [],
  income: [],
  marital_status: [],
  occupation: [],
  csv: [],
  data_unavailable: true,
};

export const scorpio_insight_domains_default: ScorpioInsightDomains = {
  title: '',
  description: '',
  data: [],
  csv: [],
  data_unavailable: true,
};

export const scorpio_insight_geographics_default: ScorpioInsightGeographics = {
  title: '',
  description: '',
  data: [],
  csv: [],
  data_unavailable: true,
};

export const scorpio_temporal_data_default: ScorpioInsightTemporalData = {
  title: '',
  label: '',
  delta: 0,
  value: 0,
  value_formatted: '',
  delta_formatted: '',
  uuid: '',
};

export const scorpio_insight_temporals_default: ScorpioInsightTemporal = {
  title: '',
  description: '',
  last_day: { ...scorpio_temporal_data_default },
  top_day: { ...scorpio_temporal_data_default },
  last_hour: { ...scorpio_temporal_data_default },
  top_hour: { ...scorpio_temporal_data_default },
  average_day_volume: { ...scorpio_temporal_data_default },
  average_hour_volume: { ...scorpio_temporal_data_default },
  visualization: {
    hours: [],
    days: [],
  },
  csv: [],
  data_unavailable: true,
};

export const scorpio_insight_keywords_default: ScorpioInsightKeywords = {
  title: '',
  description: '',
  data: [],
  csv: [],
  data_unavailable: true,
};

export const scorpio_insight_features_default: ScorpioInsightFeatures = {
  title: '',
  description: '',
  data: [],
  csv: [],
  data_unavailable: true,
};

export const scorpio_insight_predictions_default: ScorpioInsightPredictions = {
  title: '',
  description: '',
  data: [],
  csv: [],
  data_unavailable: true,
};

export const scorpio_insight_user_journey_default: ScorpioInsightUserJourneys = {
  title: '',
  description: '',
  data: [],
  csv: [],
  data_unavailable: true,
};

export const scorpio_insight_default: ScorpioInsight = {
  entity_id: 0,
  entity_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  demographics: scorpio_insight_demographics_default,
  domains: scorpio_insight_domains_default,
  geographics: scorpio_insight_geographics_default,
  temporals: scorpio_insight_temporals_default,
  keywords: scorpio_insight_keywords_default,
  features: scorpio_insight_features_default,
  predictions: scorpio_insight_predictions_default,
  user_journeys: scorpio_insight_user_journey_default,
};
