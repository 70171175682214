import { faGreaterThanEqual, faLessThanEqual, faNotEqual } from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft, faChevronRight, faEquals, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import {
  ATTRIBUTE_CATEGORY_TYPE,
  ATTRIBUTE_GROUP_ID,
  FOREIGN_LANGUAGE,
  OPERATOR_ID,
  ORION_PREVIEW_SORT,
  ORION_PREVIEW_SORT_NAME,
  REFERENCE_TEXT_TYPE_ID,
} from 'products/orion/pages/segment/enums';
import { AttributeFilter, Operator, SortOrderList } from 'products/orion/pages/segment/types';

export const MIN_RELEVANCE_PROMPT = 0.8;
export const MIN_RELEVANCE_LIKED = 0.82;
export const MIN_RELEVANCE_DISLIKED = 0.85;
export const EMBEDDING_VERSION = 1;
export const DEFAULT_REFERENCE_VALUE = 0.5;
export const DEFAULT_CTR_REFERENCE_VALUE = 0.001;
export const DEFAULT_INCLUSIVITY_REFERENCE_VALUE = 2.5;
export const NEUTRAL_REFERENCE_VALUE = 2.5;
export const INCLUSIVE_REFERENCE_VALUE = 3.7;
export const MIN_SENTIMENT_REFERENCE_VALUE = 2.5;
export const MAX_SENTIMENT_REFERENCE_VALUE = 3.7;
export const MIN_INCLUSIVE_REFERENCE_VALUE = 2.5;
export const MAX_INCLUSIVE_REFERENCE_VALUE = 3.7;
export const DEFAULT_AVAILS_REFERENCE_VALUE = 1;
export const DEFAULT_LAST_SEEN_REFERENCE_VALUE = 7;

export const SORT_ORDER_LIST: SortOrderList[] = [
  {
    preview_order_by_name: ORION_PREVIEW_SORT_NAME.MOST_RELEVANT,
    preview_order_by_id: ORION_PREVIEW_SORT.RELEVANT,
    preview_order_ascending: false,
    uuid: 1,
  },
  {
    preview_order_by_name: ORION_PREVIEW_SORT_NAME.LEAST_RELEVANT,
    preview_order_by_id: ORION_PREVIEW_SORT.RELEVANT,
    preview_order_ascending: true,
    uuid: 2,
  },
  {
    preview_order_by_name: ORION_PREVIEW_SORT_NAME.MOST_AVAILS,
    preview_order_by_id: ORION_PREVIEW_SORT.AVAILS,
    preview_order_ascending: false,
    uuid: 3,
  },
  {
    preview_order_by_name: ORION_PREVIEW_SORT_NAME.LEAST_AVAILS,
    preview_order_by_id: ORION_PREVIEW_SORT.AVAILS,
    preview_order_ascending: true,
    uuid: 4,
  },
  // {
  //   preview_order_by_name: ORION_PREVIEW_SORT_NAME.NEWEST,
  //   preview_order_by_id: ORION_PREVIEW_SORT.AGE,
  //   preview_order_ascending: true,
  //   uuid: 5,
  // },
  // {
  //   preview_order_by_name: ORION_PREVIEW_SORT_NAME.OLDEST,
  //   preview_order_by_id: ORION_PREVIEW_SORT.AGE,
  //   preview_order_ascending: false,
  //   uuid: 6,
  // },
];

export const REFERENCE_TEXT_TYPE_NAME: Record<REFERENCE_TEXT_TYPE_ID, string> = {
  [REFERENCE_TEXT_TYPE_ID.PROMPT]: 'Prompt',
  [REFERENCE_TEXT_TYPE_ID.URL]: 'URL',
};

export const OPERATOR_NAME: Record<OPERATOR_ID, string> = {
  [OPERATOR_ID.EQUAL_TO]: 'Equal To',
  [OPERATOR_ID.NOT_EQUAL_TO]: 'Not Equal To',
  [OPERATOR_ID.GREATER_THAN]: 'Greater Than',
  [OPERATOR_ID.GREATER_THAN_EQUAL_TO]: 'Greater Than or Equal To',
  [OPERATOR_ID.LESS_THAN]: 'Less Than',
  [OPERATOR_ID.LESS_THAN_EQUAL_TO]: 'Less Than or Equal To',
};

export const OPERATOR_SYMBOL: Record<OPERATOR_ID, IconDefinition> = {
  [OPERATOR_ID.EQUAL_TO]: faEquals,
  [OPERATOR_ID.NOT_EQUAL_TO]: faNotEqual,
  [OPERATOR_ID.GREATER_THAN]: faChevronRight,
  [OPERATOR_ID.GREATER_THAN_EQUAL_TO]: faGreaterThanEqual,
  [OPERATOR_ID.LESS_THAN]: faChevronLeft,
  [OPERATOR_ID.LESS_THAN_EQUAL_TO]: faLessThanEqual,
};

const OPERATOR_MAP: Operator[] = Object.keys(OPERATOR_NAME).map((key) => ({
  operator_id: Number(key),
  operator_name: OPERATOR_NAME[Number(key)],
  operator_icon: OPERATOR_SYMBOL[Number(key)],
}));

export const OPERATORS: Operator[] = OPERATOR_MAP;

export const ATTRIBUTE_CATEGORY_TYPE_NAME: Record<ATTRIBUTE_CATEGORY_TYPE, string> = {
  [ATTRIBUTE_CATEGORY_TYPE.INCLUSIVITY]: 'Inclusivity',
  [ATTRIBUTE_CATEGORY_TYPE.ETHNICITY]: 'Ethnicity',
  [ATTRIBUTE_CATEGORY_TYPE.AGE]: 'Age',
  [ATTRIBUTE_CATEGORY_TYPE.SEX]: 'Sex',
  [ATTRIBUTE_CATEGORY_TYPE.FOREIGN_LANGUAGE]: 'Foreign Language',
  [ATTRIBUTE_CATEGORY_TYPE.VIEWABILITY]: 'Viewability',
  [ATTRIBUTE_CATEGORY_TYPE.SENTIMENT]: 'Sentiment',
  [ATTRIBUTE_CATEGORY_TYPE.VCR]: 'VCR',
  [ATTRIBUTE_CATEGORY_TYPE.CTR]: 'CTR',
  [ATTRIBUTE_CATEGORY_TYPE.AVAILS]: 'Avails',
  [ATTRIBUTE_CATEGORY_TYPE.LAST_SEEN]: 'Last Seen',
};

export const ATTRIBUTE_NAME: Record<ATTRIBUTE_GROUP_ID, string> = {
  [ATTRIBUTE_GROUP_ID.INCLUSIVITY]: 'Inclusivity',
  [ATTRIBUTE_GROUP_ID['19_29']]: '19-29',
  [ATTRIBUTE_GROUP_ID['30_39']]: '30-39',
  [ATTRIBUTE_GROUP_ID['40_49']]: '40-49',
  [ATTRIBUTE_GROUP_ID['50_59']]: '50-59',
  [ATTRIBUTE_GROUP_ID['60_64']]: '60-64',
  [ATTRIBUTE_GROUP_ID['65+']]: '65+',
  [ATTRIBUTE_GROUP_ID.FEMALE]: 'Female',
  [ATTRIBUTE_GROUP_ID.MALE]: 'Male',
  [ATTRIBUTE_GROUP_ID.AMERICAN_INDIAN_ALASKA_NATIVE]: 'American Indian or Alaska Native',
  [ATTRIBUTE_GROUP_ID.ASIAN]: 'Asian',
  [ATTRIBUTE_GROUP_ID.BLACK_AFRICAN_AMERICAN]: 'Black or African American',
  [ATTRIBUTE_GROUP_ID.HISPANIC_LATINO]: 'Hispanic or Latino',
  [ATTRIBUTE_GROUP_ID.PACIFIC_ISLANDER]: 'Pacific Islander',
  [ATTRIBUTE_GROUP_ID.WHITE]: 'White',
  [ATTRIBUTE_GROUP_ID.FOREIGN_LANGUAGE]: 'Foreign Language',
  [ATTRIBUTE_GROUP_ID.SENTIMENT]: 'Sentiment',
  [ATTRIBUTE_GROUP_ID.VIEWABILITY]: 'Viewability',
  [ATTRIBUTE_GROUP_ID.VCR]: 'VCR',
  [ATTRIBUTE_GROUP_ID.CTR]: 'CTR',
  [ATTRIBUTE_GROUP_ID.AVAILS]: 'Avails',
  [ATTRIBUTE_GROUP_ID.LAST_SEEN]: 'Last Seen',
};

export const ATTRIBUTE_CATEGORY_TYPE_MAP: Record<ATTRIBUTE_GROUP_ID, ATTRIBUTE_CATEGORY_TYPE> = {
  [ATTRIBUTE_GROUP_ID.INCLUSIVITY]: ATTRIBUTE_CATEGORY_TYPE.INCLUSIVITY,
  [ATTRIBUTE_GROUP_ID['19_29']]: ATTRIBUTE_CATEGORY_TYPE.AGE,
  [ATTRIBUTE_GROUP_ID['30_39']]: ATTRIBUTE_CATEGORY_TYPE.AGE,
  [ATTRIBUTE_GROUP_ID['40_49']]: ATTRIBUTE_CATEGORY_TYPE.AGE,
  [ATTRIBUTE_GROUP_ID['50_59']]: ATTRIBUTE_CATEGORY_TYPE.AGE,
  [ATTRIBUTE_GROUP_ID['60_64']]: ATTRIBUTE_CATEGORY_TYPE.AGE,
  [ATTRIBUTE_GROUP_ID['65+']]: ATTRIBUTE_CATEGORY_TYPE.AGE,
  [ATTRIBUTE_GROUP_ID.FEMALE]: ATTRIBUTE_CATEGORY_TYPE.SEX,
  [ATTRIBUTE_GROUP_ID.MALE]: ATTRIBUTE_CATEGORY_TYPE.SEX,
  [ATTRIBUTE_GROUP_ID.AMERICAN_INDIAN_ALASKA_NATIVE]: ATTRIBUTE_CATEGORY_TYPE.ETHNICITY,
  [ATTRIBUTE_GROUP_ID.ASIAN]: ATTRIBUTE_CATEGORY_TYPE.ETHNICITY,
  [ATTRIBUTE_GROUP_ID.BLACK_AFRICAN_AMERICAN]: ATTRIBUTE_CATEGORY_TYPE.ETHNICITY,
  [ATTRIBUTE_GROUP_ID.HISPANIC_LATINO]: ATTRIBUTE_CATEGORY_TYPE.ETHNICITY,
  [ATTRIBUTE_GROUP_ID.PACIFIC_ISLANDER]: ATTRIBUTE_CATEGORY_TYPE.ETHNICITY,
  [ATTRIBUTE_GROUP_ID.WHITE]: ATTRIBUTE_CATEGORY_TYPE.ETHNICITY,
  [ATTRIBUTE_GROUP_ID.FOREIGN_LANGUAGE]: ATTRIBUTE_CATEGORY_TYPE.FOREIGN_LANGUAGE,
  [ATTRIBUTE_GROUP_ID.SENTIMENT]: ATTRIBUTE_CATEGORY_TYPE.SENTIMENT,
  [ATTRIBUTE_GROUP_ID.VIEWABILITY]: ATTRIBUTE_CATEGORY_TYPE.VIEWABILITY,
  [ATTRIBUTE_GROUP_ID.VCR]: ATTRIBUTE_CATEGORY_TYPE.VCR,
  [ATTRIBUTE_GROUP_ID.CTR]: ATTRIBUTE_CATEGORY_TYPE.CTR,
  [ATTRIBUTE_GROUP_ID.AVAILS]: ATTRIBUTE_CATEGORY_TYPE.AVAILS,
  [ATTRIBUTE_GROUP_ID.LAST_SEEN]: ATTRIBUTE_CATEGORY_TYPE.LAST_SEEN,
};

export const RANGE_INCLUSIVITY_OPTIONS: Record<'LESS_THAN_MAX' | 'GREATER_THAN_MIN', AttributeFilter> = {
  LESS_THAN_MAX: {
    attribute_category_type_id: ATTRIBUTE_CATEGORY_TYPE.INCLUSIVITY,
    attribute_category_type_name: ATTRIBUTE_CATEGORY_TYPE_NAME[ATTRIBUTE_CATEGORY_TYPE.INCLUSIVITY],
    attribute_group_id: ATTRIBUTE_GROUP_ID.INCLUSIVITY,
    attribute_group_name: ATTRIBUTE_NAME[ATTRIBUTE_GROUP_ID.INCLUSIVITY],
    operator_id: OPERATOR_ID.LESS_THAN,
    operator_name: OPERATOR_NAME[OPERATOR_ID.LESS_THAN],
    reference_value: MAX_INCLUSIVE_REFERENCE_VALUE,
  },
  GREATER_THAN_MIN: {
    attribute_category_type_id: ATTRIBUTE_CATEGORY_TYPE.INCLUSIVITY,
    attribute_category_type_name: ATTRIBUTE_CATEGORY_TYPE_NAME[ATTRIBUTE_CATEGORY_TYPE.INCLUSIVITY],
    attribute_group_id: ATTRIBUTE_GROUP_ID.INCLUSIVITY,
    attribute_group_name: ATTRIBUTE_NAME[ATTRIBUTE_GROUP_ID.INCLUSIVITY],
    operator_id: OPERATOR_ID.GREATER_THAN,
    operator_name: OPERATOR_NAME[OPERATOR_ID.GREATER_THAN],
    reference_value: MIN_INCLUSIVE_REFERENCE_VALUE,
  },
};

export const RANGE_SENTIMENT_OPTIONS: Record<'LESS_THAN_MAX' | 'GREATER_THAN_MIN', AttributeFilter> = {
  LESS_THAN_MAX: {
    attribute_category_type_id: ATTRIBUTE_CATEGORY_TYPE.SENTIMENT,
    attribute_category_type_name: ATTRIBUTE_CATEGORY_TYPE_NAME[ATTRIBUTE_CATEGORY_TYPE.SENTIMENT],
    attribute_group_id: ATTRIBUTE_GROUP_ID.SENTIMENT,
    attribute_group_name: ATTRIBUTE_NAME[ATTRIBUTE_GROUP_ID.SENTIMENT],
    operator_id: OPERATOR_ID.LESS_THAN,
    operator_name: OPERATOR_NAME[OPERATOR_ID.LESS_THAN],
    reference_value: MAX_SENTIMENT_REFERENCE_VALUE,
  },
  GREATER_THAN_MIN: {
    attribute_category_type_id: ATTRIBUTE_CATEGORY_TYPE.SENTIMENT,
    attribute_category_type_name: ATTRIBUTE_CATEGORY_TYPE_NAME[ATTRIBUTE_CATEGORY_TYPE.SENTIMENT],
    attribute_group_id: ATTRIBUTE_GROUP_ID.SENTIMENT,
    attribute_group_name: ATTRIBUTE_NAME[ATTRIBUTE_GROUP_ID.SENTIMENT],
    operator_id: OPERATOR_ID.GREATER_THAN,
    operator_name: OPERATOR_NAME[OPERATOR_ID.GREATER_THAN],
    reference_value: MIN_SENTIMENT_REFERENCE_VALUE,
  },
};

export const FOREIGN_LANGUAGE_NAME: Record<FOREIGN_LANGUAGE, string> = {
  [FOREIGN_LANGUAGE.CHINESE]: 'Chinese',
  [FOREIGN_LANGUAGE.DANISH]: 'Danish',
  [FOREIGN_LANGUAGE.ENGLISH]: 'English',
  [FOREIGN_LANGUAGE.FRENCH]: 'French',
  [FOREIGN_LANGUAGE.JAPANESE]: 'Japanese',
  [FOREIGN_LANGUAGE.SPANISH]: 'Spanish',
  [FOREIGN_LANGUAGE.PORTUGUESE]: 'Portuguese',
  [FOREIGN_LANGUAGE.GERMAN]: 'German',
  [FOREIGN_LANGUAGE.DUTCH]: 'Dutch',
  [FOREIGN_LANGUAGE.ITALIAN]: 'Italian',
  [FOREIGN_LANGUAGE.SWEDISH]: 'Swedish',
  [FOREIGN_LANGUAGE.NORWEGIAN]: 'Norwegian',
  [FOREIGN_LANGUAGE.FINNISH]: 'Finnish',
};
